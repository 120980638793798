import React, { useEffect, useState, useRef } from 'react';

import HeaderBar from '../../../components/header-bar/index.jsx';
import TabBar from '../../../components/tab-bar/index.jsx';
import CategoryProductItem from '../../../components/category-product-item/index.jsx';
import demoImageProduct from '../../../images/demo/category-product.png';
import { navigate } from 'gatsby';
import WebFn, { callAppFunc } from '../../../utils/jsbridge/index.js';
import { useAPI } from '../../../utils/api';
import { showLoading, dismissLoading } from '../../../utils/loading';
import { pageCheck } from '../../../utils/pageCheck';
import InfiniteScroll from 'react-infinite-scroll-component';

import store, { actions, getters } from '../../../redux/store';
import { useSelector } from 'react-redux';

import ContentLoader from 'react-content-loader';
import { setSelectItem } from '../../../utils/_ga';

import { setAppsFlyerListView } from '../../../utils/_appsflyer.js';

let currentPage = 1;
let totalPage = 0;
const perPage = 24;
let categoryId = '';
let arrProducts = [];

const PAGE_CACHE_KEY = 'category-product-list';

const CategoryTitlePreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  return (
    <>
      <ContentLoader
        viewBox={`0 0 ${358 * ratio} 26`}
        height={26}
        width={358 * ratio}
      >
        <rect x={0} y={0} rx="0" ry="0" width={105 * ratio} height="26" />
      </ContentLoader>
    </>
  );
};

const CategoryProductPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  return (
    <>
      <ContentLoader
        viewBox={`0 0 ${358 * ratio} 1000`}
        height={1000}
        width={358 * ratio}
      >
        {[...Array(5)].map((i, index) => {
          return (
            <>
              {[...Array(3)].map((j, subIndex) => {
                return (
                  <>
                    <rect
                      x={subIndex * 127 * ratio}
                      y={20 + 220 * index}
                      rx="8"
                      ry="8"
                      width={105 * ratio}
                      height="140"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={170 + 220 * index}
                      rx="0"
                      ry="0"
                      width={30 * ratio}
                      height="16"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={190 + 220 * index}
                      rx="0"
                      ry="0"
                      width={105 * ratio}
                      height="16"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={210 + 220 * index}
                      rx="0"
                      ry="0"
                      width={80 * ratio}
                      height="16"
                    />
                  </>
                );
              })}
            </>
          );
        })}
      </ContentLoader>
    </>
  );
};

const CategoryProduct = ({ location, CategoryId }) => {
  const api = useAPI();

  const [products, setProducts] = useState([]);
  const [categoryName, setCategoryName] = useState(undefined);
  const [hasMore, setHasMore] = useState(false);
  const [cartNum, setCartNum] = useState(0);
  const [info, setInfo] = useState({});
  const [showPage, setShowPage] = useState(false);

  const userInfo = useSelector((state) => getters.getUserInfo(state));

  const storeId = useSelector((state) => getters.getStoreId(state));

  const localCacheData = useSelector((state) => getters.getLocalCache(state));

  const keepaliveData = useSelector((state) => getters.getKeepalive(state));

  const onLoading = useRef(false);

  const productsList = useRef();
  const pageName = '分類商品頁';

  const notificationDataRef = useRef(null);

  // 接收資料的處理函數
  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    if (dataJson.fnName === 'sendNotificationData') {
      notificationDataRef.current = dataJson.params.data;
    }
  };

  const getInfo = (fromCache = false) => {
    api
      .getInfo()
      .then((res) => {
        dismissLoading();
        const num = res.cart.count;
        setCartNum(num);
        setInfo(res);

        const newProds = [];
        arrProducts.forEach((p) => {
          let result = res.cart.productCount.find((infoProd) => {
            return p.id === infoProd.productNo;
          });
          const asProd = Object.assign({}, p);

          if (result !== undefined) {
            asProd.qty = result.count;
          } else {
            asProd.qty = 0;
          }
          newProds.push(asProd);
        });

        setShowPage(true);
        setProducts(newProds);

        if (fromCache) {
          const offsetData =
            keepaliveData[
              `category-list-product-offset-${storeId}-${categoryId}`
            ];

          if (offsetData !== undefined && offsetData !== null) {
            window.scrollTo(0, offsetData.position);
          }
        }
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  };

  const getProducts = () => {
    if (arrProducts.length <= 0) {
      const productsCache =
        localCacheData[`${PAGE_CACHE_KEY}-${storeId}-${categoryId}-products`];

      if (productsCache !== undefined && productsCache !== null) {
        currentPage = productsCache.currentPage;
        totalPage = productsCache.totalPage;
        arrProducts = productsCache.products;
        setHasMore(productsCache.hasMore);
        getInfo(true);

        return;
      }
    }

    if (currentPage === 1 || currentPage <= totalPage) {
      setHasMore(true);

      const customerNo = userInfo.customerNo;
      // if (currentPage === 1) {
      //   showLoading();
      // }
      onLoading.current = true;
      api
        .getCategoriesProducts({
          categoryId,
          currentPage,
          perPage,
          storeId,
          customerNo,
        })
        .then((res) => {
          onLoading.current = false;
          dismissLoading();
          if (!res) {
            callAppFunc('storeClose', {});
            return;
          }

          // setAppsFlyerListView(categoryId, res);

          const rowArr = res.rows;

          arrProducts = [...arrProducts, ...rowArr];
          currentPage = currentPage + 1;
          totalPage = Number.parseInt(res.total / perPage);
          if (res.total % perPage !== 0) {
            totalPage++;
          }

          if (res.total === res.rows.length) {
            setHasMore(false);
          }

          getInfo();
        })
        .catch((error) => {
          onLoading.current = false;
          dismissLoading();
          console.log(error);
        });
    } else {
      setShowPage(true);
      setHasMore(false);
    }
  };

  useEffect(() => {
    WebFn({ callback: jsBridgeCallback });
    // 呼叫fetchNotificationData
    callAppFunc('fetchNotificationData', {});
    arrProducts = [];
  }, []);

  return (
    <>
      <div className="category-product ">
        <HeaderBar
          fixed
          showCart
          cartCountExternal={cartNum}
          onCartClick={() => {
            if (cartNum > 0) {
              pageCheck(api, () => {
                navigate('/cart', {
                  state: { page: '分類商品頁' },
                  replace: true,
                });
              });
            } else {
              alert('還沒有加入商品喔~');
            }
          }}
          title={'分類商品'}
          goBack={() => {
            store.dispatch(actions.cleanKeepalive());
            callAppFunc('backHome', {});
          }}
        />
        <div className="tab-bar">
          <TabBar
            onRefresh={(cateId, cateName) => {
              // cache
              if (!onLoading.current) {
                if (
                  products.length > 0 &&
                  products[0]?.categoryId === categoryId
                ) {
                  const cacheData = {
                    key: `${PAGE_CACHE_KEY}-${storeId}-${categoryId}-products`,
                    data: {
                      currentPage: currentPage,
                      totalPage: totalPage,
                      products: products,
                      hasMore: hasMore,
                    },
                  };
                  store.dispatch(actions.addLocalCache(cacheData));

                  const keepaliveData = {
                    key: `category-list-product-offset-${storeId}-${categoryId}`,
                    data: {
                      position: productsList.current.el.pageYOffset,
                    },
                  };
                  store.dispatch(actions.addKeepalive(keepaliveData));
                }
              }

              localStorage.removeItem('categoryId');
              setShowPage(false);
              currentPage = 1;
              totalPage = 0;
              categoryId = cateId;
              arrProducts = [];
              setCategoryName(cateName);

              getProducts();
            }}
            cateId={
              localStorage.getItem('categoryId') !== null
                ? localStorage.getItem('categoryId')
                : CategoryId
            }
          />
        </div>
        <div className="title">
          {categoryName === undefined ? (
            <CategoryTitlePreview></CategoryTitlePreview>
          ) : (
            categoryName
          )}
        </div>
        {
          showPage ? (
            <InfiniteScroll
              ref={productsList}
              scrollThreshold="0.7"
              dataLength={products.length}
              next={getProducts}
              hasMore={hasMore}
              style={{ height: '100%', overflow: 'unset' }}
              loader={<h6>資料載入中...請稍候</h6>}
            >
              <div className="product-list">
                {products &&
                  products.map((item, idx) => (
                    <div className="product-item" key={`${idx}-${item.id}`}>
                      <CategoryProductItem
                        data={item}
                        pageName={pageName}
                        searchWord={item.main_category}
                        notifyType={notificationDataRef.current?.category_id} // 傳遞notifyType
                        update={() => {
                          getInfo();
                        }}
                        goNext={(id) => {
                          pageCheck(api, () => {
                            if (!onLoading.current) {
                              const cacheData = {
                                key: `${PAGE_CACHE_KEY}-${storeId}-${categoryId}-products`,
                                data: {
                                  currentPage: currentPage,
                                  totalPage: totalPage,
                                  products: products,
                                  hasMore: hasMore,
                                },
                              };
                              store.dispatch(actions.addLocalCache(cacheData));
                            }
                            localStorage.setItem('categoryId', categoryId);

                            const keepaliveData = {
                              key: `category-list-product-offset-${storeId}-${categoryId}`,
                              data: {
                                position: productsList.current.el.pageYOffset,
                              },
                            };
                            store.dispatch(actions.addKeepalive(keepaliveData));

                            setSelectItem(item.main_category, item, pageName);
                            navigate(`/product/${id}`, {
                              state: {},
                              replace: false,
                            });
                          });
                        }}
                      />
                    </div>
                  ))}
              </div>
            </InfiniteScroll>
          ) : (
            <CategoryProductPreview />
          )
          // undefined
        }
      </div>
      <style jsx>{`
        .category-product {
          min-height: 100vh;
          background: #ffffff;
          padding: 133px 16px 30px 16px;
          box-sizing: border-box;
          .header {
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
          }
          .tab-bar {
            width: 100vw;
            position: fixed;
            top: 60px;
            left: 0;
            z-index: 999;
          }
          .title {
            margin-top: 20px;
            font-weight: bold;
            font-size: 18px;
            line-height: 26px;
            color: #333333;
          }
          .product-list {
            margin-top: 8px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 8px;
            .product-item {
            }
            div:nth-child(3n) {
              margin-right: 0px;
            }
          }
        }
      `}</style>
    </>
  );
};

export default CategoryProduct;
